if (document.querySelector('.top_nav_link')) {
    const frontArrow = document.getElementById('leftArrow')
    const endArrow = document.getElementById('rightArrow');
    let list = document.getElementById('navList');


    if (list.scrollWidth > list.clientWidth) {
        endArrow.style.display = 'inline-block'
    }

    endArrow.addEventListener('click', () => {
        list.scrollTo({
            left: list.scrollLeft + 200,
            behavior: "smooth",
        })
        frontArrow.style.display = 'inline-block';

        if (list.scrollLeft >= (list.scrollWidth - list.clientWidth) - 150) {
            endArrow.style.display = 'none';
        }
    })

    frontArrow.addEventListener('click', () => {
        list.scrollTo({
            left: list.scrollLeft - 200,
            behavior: "smooth",
        })
        //frontArrow.style.display = 'none';
        endArrow.style.display = 'inline-block';

        if (list.scrollLeft <= 200) {
            list.scrollTo({
                left: 0,
                behavior: "smooth",
            })
            frontArrow.style.display = 'none';
        }
    })
}