let hamburgerIcon = document.querySelector("#sidenav-open");
let closeIcon = document.querySelector("#sidenav-close");
let sidebar = document.querySelector("#sidenavLinks")

hamburgerIcon.addEventListener("click", toggleSidebar)
closeIcon.addEventListener('click', toggleSidebar)

function handleClick(e) {
    if (e.target.closest('.sidenav')==null) {
        sidebar.style.left = "-20em";
        document.body.removeEventListener('click', handleClick);
    }
}

function toggleSidebar(e) {
    e.stopPropagation();
    if (sidebar.style.left == "-20em") {
        sidebar.style.left = "0em";
        document.body.addEventListener('click', handleClick);
    } else {
        sidebar.style.left = "-20em";
        document.body.removeEventListener('click', handleClick);
    }
}

/*function openSidebar (){
    sidebar.scss.left="0";
    //sidebar.scss.left = "200px";
    console.log("openSidebar fired");
}

function closeSidebar() {
    sidebar.scss.left="-20em"
    //sidebar.scss.left = "-20em";
    console.log('closeSidebar fired')
}
*/
let sections = document.querySelectorAll(".sidenav-section-list.dropdown-content")
let sectionTitle = document.querySelectorAll("button.sidenav-section-title")
let st

for (i=0;i<sectionTitle.length;i++){
    st = sectionTitle[i]
    console.log(st)
    st.addEventListener("click", showSection)
}

function showSection(e){
    let sections = Array.from(sectionTitle)
    let activeSection = e.target;
    let i = sections.indexOf(activeSection);
    sections.splice(i, 1)
    console.log(sections)
    let dropDown = activeSection.nextElementSibling
    if (dropDown.classList.contains("hidden")) {
        dropDown.classList.remove("hidden")
    } else {
        dropDown.classList.add("hidden")
    }
    /*let icon = activeSection.firstElementChild
    if (icon.classList.contains('fa-angle-right')) {
        icon.classList.remove("fa-angle-right")
        icon.classList.add("fa-angle-down")
    } else if (icon.classList.contains('fa-angle-down')) {
        icon.classList.remove('fa-angle-down')
        icon.classList.add('fa-angle-right')
    }*/
    sections.forEach(s => {
        if (s.nextElementSibling.classList.contains("hidden") === false) {
            s.nextElementSibling.classList.add("hidden")
            }
        }
    )
}

let sidebarSearchButton = document.querySelector(".search-btn")
let sidebarSearchButtonIV = document.querySelector(".search-input")

sidebarSearchButton.addEventListener("click", function(){
    event.preventDefault()
    let url = "/search/?q=" + sidebarSearchButtonIV.value
    window.location.href = url
})
