const popularURL = "https://experiences.mrf.io/recommenderexperience/render.json?id=AP_8SooXdmRT9mu99ETU0V4Ow&canonical_url=https://" + window.location.host
let popHTML = document.querySelector(".most_read")

fetch(popularURL)
    .then((response) => response.json())
    .then((json) => {
        let popArray = json.recommendation;
        popArray.forEach(function(v, i, a){
            let li = document.createElement("li");
            popHTML.appendChild(li)
            let h4 = document.createElement("h4")
            li.appendChild(h4)
            let popLink = v.url.split("?utm_rs=")
            let popLinkParsed = popLink[0] + "?utm_ps=" + popLink[1]
            let plink = document.createElement("a")
            plink.setAttribute("href", popLinkParsed)
            h4.appendChild(plink)
            let title = document.createTextNode(v.title)
            plink.appendChild(title)
        })
    });