

let fbShare = document.querySelector('#fblink');
let twitterShare = document.querySelector('#twitterlink');
let linkedinShare = document.querySelector('#linkedinlink');
let pageUrl =  encodeURIComponent(window.location.href);

function socialWindow(url) {
    var left = (screen.width - 570) / 2;
    var top = (screen.height - 570) / 2;
    var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
    window.open(url,"NewWindow",params);
}

function setShareLinkedIn() {
    let linkedin_url = "https://www.linkedin.com/sharing/share-offsite/?url=" + pageUrl;

    socialWindow(linkedin_url);
}

function setShareFB() {
    let fblink = "https://www.facebook.com/sharer.php?u=" + pageUrl;
    socialWindow(fblink);
}

function setShareTW() {
    let twitterText = document.querySelector('meta[name="twitter:description"]').content;
    let twText = encodeURIComponent(twitterText);
    let twitter_url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + twitterText + "&source=" + pageUrl;
    socialWindow(twitter_url);
}

try {
    fbShare.addEventListener("click", function() {
        setShareFB();
    } );
} catch (e) {
    console.log("Error sharing to FB:", e);
};

try {
twitterShare.addEventListener("click", function(e) {
    e.preventDefault();
    setShareTW();
} );
} catch (e) {
    console.log("Error sharing to X:", e);
}

try {
linkedinShare.addEventListener("click", function(e) {
    e.preventDefault();
    setShareLinkedIn();
} );
} catch (e) {
    console.log("Error sharing to LinkedIn:", e);
}