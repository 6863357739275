import {onLCP, onINP, onCLS, onTTFB, onFCP} from 'web-vitals';

function sendToAnalytics(metric) {
    metric.pageURL = window.location.href;
    const body = JSON.stringify(metric);
    console.log(body);
    console.log(typeof(body));

    (navigator.sendBeacon && navigator.sendBeacon('https://log.arkansasonline.com/analytics', body)) ||
    fetch('https://log.arkansasonline.com/analytics',
        {
            body: body,
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            keepalive: true
        })
        .then(res => (console.log(res.text())))
}

onLCP(sendToAnalytics);
onFCP(sendToAnalytics);
onTTFB(sendToAnalytics);
onCLS(sendToAnalytics);
onINP(sendToAnalytics);