import FontFaceObserver from "fontfaceobserver";

const HEADLINEFONT = new FontFaceObserver("FarnhamHeadline", {
    weight: 400
})

const OSWALDFONT = new FontFaceObserver("Oswald", {
    weight: 400
})

const SUBEXFONT = new FontFaceObserver("OswaldDemiBold", {
  weight: 600
})

const HEADLINEBOLD = new FontFaceObserver("FarnhamHeadlineSemiBold", {
    weight: 600
})

const STORYBODY = new FontFaceObserver("FarnhamTextRegular", {
    weight: 400
})

HEADLINEFONT.load().then(function(){
    let heads = document.querySelectorAll(".headline_fallback_font");
    if (heads) {
        heads.forEach(function(headline){
            headline.classList.add("headline_font_loaded")
            headline.classList.remove("headline_fallback_font")
        })
    }
})

OSWALDFONT.load().then(function(){
    let sans = document.querySelectorAll(".oswald_fallback_font")
    if (sans) {
        sans.forEach(function(sansElement){
            sansElement.classList.add("sans_font_loaded")
            sansElement.classList.remove("oswald_fallback_font")
        })
    }
})

SUBEXFONT.load().then(function(){
    let subex = document.querySelectorAll(".sub-ex-fallback")
    if (subex) {
        subex.forEach(function(subexElement){
            subexElement.classList.add("sub-ex-font")
            subexElement.classList.remove("sub-ex-fallback")
        })
    }
})

STORYBODY.load().then(function(){
    let story = document.querySelectorAll(".copy_fallback_font")
    if (story) {
        story.forEach(function(storyElement){
            storyElement.classList.add("copy_text_loaded")
            storyElement.classList.remove("copy_fallback_font")
        })
    }
})